<template>
  <el-form inline :model="selectModel" label-position="right">
    <el-form-item label="学校">
      <el-select v-model="selectModel.collegeId" placeholder="请选择" clearable>
        <el-option v-for="item in colleges" :key="item.id" :value="item.id" :label="item.val" />
      </el-select>
    </el-form-item>
    <el-form-item label="日期">
      <el-date-picker
        v-model="selectDatetime"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期" />
    </el-form-item>
    <el-form-item class="main-form-item-float-right">
      <el-button-group>
        <el-button type="primary" icon="el-icon-tickets" @click="exportXLSX">导出</el-button>
        <el-button type="primary" icon="el-icon-search" @click="queryTableData">查询</el-button>
      </el-button-group>
    </el-form-item>
  </el-form>

  <el-table
    :data="tableData"
    border
    stripe
    :default-sort="defaultSort"
    @sort-change="sort"
    style="width: 100%;">
    <el-table-column label="ID" prop="id" width="100px" align="center" sortable="custom" />
    <el-table-column label="日期" prop="date" min-width="100px" />
    <el-table-column label="学校名称" prop="collegeName" min-width="200px" />
    <el-table-column label="余额营收" prop="localAmount" min-width="80px" />
    <el-table-column label="余额退款" prop="localRefund" min-width="80px" />
    <el-table-column label="微信营收" prop="wechatAmount" min-width="80px" />
    <el-table-column label="微信退款" prop="wechatRefund" min-width="80px" />
    <el-table-column label="订单数" prop="machineOrder" min-width="80px" />
    <el-table-column label="充值" prop="rechargeAmount" min-width="80px" />
  </el-table>
  <div style="margin-top: 10px; text-align: right;">
    <el-pagination
      layout="prev, pager, next,jumper"
      :total="total"
      @current-change="applyCurrentPage" />
  </div>
</template>

<script>
import { handleCatchStatus } from '../../api/handler';
import { formatDate } from '../../utils/datetime';
import moment from 'moment';
import XLSX from 'xlsx';
import _ from 'lodash';

export default {
  data() {
    return {
      selectDatetime: [],
      selectModel: {},
      selectSort: null,
      defaultSort: {prop: 'id', order: 'descending'},
      tableData: [],
      total: 0,
      current: 1,
      dialog: {
        visibled: false,
        created: false,
        readonly: false,
        title: '',
        form: {},
        submit: () => true
      },
      colleges: []
    };
  },
  created() {
    this.$api.getCollegeMap().then(data => {
      this.colleges = data;
    });
    this.applyDay7();
    this.applySortSelect(this.defaultSort);
    this.queryTableData();
  },
  mounted() {
  },
  methods: {
    queryTableData() {
      this.applyDatetime();
      this.selectModel.sort = this.selectSort;
      this.selectModel.page = this.current - 1;
      this.$api.getReportCollegeOrderPage(this.selectModel)
        .then(data => {
          this.tableData = data.content;
          this.total = data.totalElements;
        })
        .catch(handleCatchStatus);
    },
    applyDatetime() {
      if (this.selectDatetime && this.selectDatetime.length === 2) {
        this.selectModel.startTime = formatDate(this.selectDatetime[0]);
        this.selectModel.endTime = formatDate(this.selectDatetime[1]);
      } else {
        this.selectModel.startTime = null;
        this.selectModel.endTime = null;
      }
    },
    applyDay7() {
      const now = moment().toDate();
      const day7 = moment().subtract(7, 'days').toDate();
      this.selectDatetime = [day7, now];
    },
    sort({ prop, order }) {
      this.applySortSelect({ prop, order });
      this.queryTableData();
    },
    applySortSelect({ prop, order }) {
      if (order) {
        this.selectSort = `${prop},${order === 'ascending' ? 'ASC' : 'DESC'}`;
        return;
      }
      this.selectSort = null;
    },
    applyCurrentPage(current) {
      this.current = current;
      this.queryTableData();
    },
    exportXLSX() {
      const wb = XLSX.utils.book_new();
      const ws_name = 'SheetJS';
      const type = 'xlsx';
      const queryDto = Object.assign({}, this.selectModel, {begin: 0, size: 10000});
      return this.$api.getReportCollegeOrderPage(queryDto)
        .then(data => {
          const title = {
            'date': '日期',
            'collegeName': '学校',
            'localAmount': '余额营收',
            'localRefund': '余额退款',
            'wechatAmount': '微信营收',
            'wechatRefund': '微信退款',
            'machineOrder': '订单数',
            'rechargeAmount': '充值'
          };
          const header = Object.keys(title);
          data.content.unshift(title);
          const jsond = data.content.map(item => _.pick(item, header));
          const ws = XLSX.utils.json_to_sheet(jsond, {header, skipHeader: true});
          XLSX.utils.book_append_sheet(wb, ws, ws_name);
          return XLSX.writeFile(wb, '订单日报表明细.' + (type || 'xlsx'));
        });
    }
  }
};
</script>
